<template>
    <div>
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>

        </div>
        <v-form ref="loginForm"
                class="multi-col-validation"
                lazy-validation
                v-model="valid">
            <v-row>
                <v-col cols="12"
                       lg="12"
                       md="12"
                       sm="12">
                    <div>

                        <div style="margin: auto;">
                            <div>
                                <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-8"
                                   style="font-size: 14px;">
                                    {{ $t('Please enter activation code sent to you over SMS.') }}
                                </p>
                                <div dir="ltr"
                                     class="otp-input">
                                    <v-otp-input v-model="otp"
                                                 :rules="genericRules"
                                                 @input="onOtpInput"
                                                 @blur="onInputBlur"
                                                 ref="otpInput"
                                                 :hide-details="true"
                                                 type="number"
                                                 @keydown.enter="verifyOPTAndGetDetails"
                                                 onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                 class="mb-2"
                                                 length="4">
                                    </v-otp-input>
                                    <div v-if="otpError"
                                         style="font-size: 12px;"
                                         class="ar-otp error-message">
                                        {{ $t(otpErrorMessage) }}
                                    </div>
                                </div>

                                <v-btn color="primary"
                                       type="button"
                                       style="width: 100%"
                                       :disabled="otp.length < 4"
                                       @click="verifyOPTAndGetDetails"
                                       x-large
                                       class="arabic-font mt-6 py-3 btn-dark">
                                    {{ $t('Next') }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import alert from './alert.vue';
import sharedServices from '@/services/shared-services'
import enrollmentServices from '@/services/enrollment-services'
import store from '@/store'
import restErrorMessages from '@/@core/utils/rest-error-messages';


export default {
    components: {
        AppBarI18n,
        AppBarThemeSwitcher,
        alert
    },
    data: () => ({
        valid: false,
        // First Form
        legalId: '', // From User Input
        captchaValue: '', // From UserInput
        captchaId: '', // From API
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        otpError: false,
        // Second Form
        otp: '', // From USer Input

        showOTP: false,
        captchaImage: '', // From API to Display Captcha Image
        genericRules: [
            v => !!v || 'Field is required'
        ],
        captchaRules: [
            (value) => !!value || 'Captcha is required.',
            (value) => (value && value.length >= 6) || 'Captcha must be 6 digits',
        ],

        nationalRules: [
            (value) => !!value || 'Personal ID is required.',
            (value) => (value && value.length >= 10) || 'Personal ID must be 10 digits',
        ],
    }),
    mounted()
    {
        window.scrollTo(0, 0);
        this.requestCaptcha();
    },
    methods: {
        onOtpInput()
        {

            if (this.otp.length !== 4)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Field is required';
            } else
            {
                this.otpError = false;
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            }
        },
        onInputBlur()
        {
            if (!this.otp.length)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Field is required';
            }
        },
        onFinish(rsp)
        {
            this.disableSubmit = false;
        },
        submit()
        {
            this.$emit('submit')
        },
        back()
        {
            this.$emit('back')
        },
        requestCaptcha()
        {
            sharedServices.getCaptcha().then(captchaResponse =>
            {
                this.captchaImage = 'data:image/jpg;base64,' + captchaResponse.data.captcha;
                this.captchaId = captchaResponse.data.captchaId;
            })
        },
        verifyAndGetOTP()
        {

            let dict = {
                captchaId: this.captchaId,
                captchaValue: this.captchaValue,
            }

            enrollmentServices.verifyAndGetOTP(dict).then(otpResponse =>
            {
                if (otpResponse?.data?.responseInfo?.errorCode == "00000")
                {
                    this.showOTP = true;
                }
                else
                {
                    if (otpResponse?.data?.responseInfo?.errorCode != "00000")
                    {
                        // sharedServices.handelError('Error', otpResponse?.data?.responseInfo?.errorDescription, 'error')
                        // this.alertMessage = otpResponse?.data?.responseInfo?.errorDescription
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }

                    else if (otpResponse?.error?.response?.status != 200)
                    {
                        // sharedServices.handelError('Error', otpResponse?.error?.response?.data?.responseInfo?.errorDescription, 'error')
                        // this.alertMessage = otpResponse?.error?.response?.data?.responseInfo?.errorDescription
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }

            })
        },
        verifyOPTAndGetDetails()
        {
            if (this.otp.length !== 4)
            {
                return;
            }
            let dict = {
                captchaId: this.captchaId,
                captchaValue: this.captchaValue,
                otp: this.otp
            }

            enrollmentServices.verifyOPTAndGetDetails(dict).then(otpResponse =>
            {

                if (otpResponse.data.responseInfo.errorCode != "00000")
                {
                    // sharedServices.handelError('Error', otpResponse.data.responseInfo.errorDescription, 'error')
                    this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
                else
                {
                    localStorage.setItem("userName", otpResponse.data.existingUserName);
                    localStorage.setItem('userData', JSON.stringify(otpResponse.data));
                    this.submit();
                }

            })
        }
    },
    watch: {
        legalId: function (newVal, oldVal)
        {
            store.set('enrollment/legalId', newVal);
        }
    },
    setup()
    {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.main-width {
    width: 502px;
}

@media screen and (max-width: 630px) {
    .main-width {
        width: 100% !important;
    }

}

.secure-img {
    background: #E2F1FD;
    width: 123px;
    height: 123px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
}

.otp-input {
    width: 290px;
}

.security-text {
    width: 260px;
}

.divide {
    height: 235px;
    position: relative;
    left: 74px;
    right: 74px;
    top: 48px;
}

@media screen and (max-width: 950px) {

    .divide {
        display: none;
    }
}

@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }

}

@media screen and (max-width: 500px) {

    .otp-input {
        width: 100% !important;
    }

    .security-text {
        width: 100%;
    }

}
</style>
