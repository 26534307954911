<template>
    <div>
        <TermsCondition @close="closeDialog"
                        :modal="modall"></TermsCondition>
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>

        </div>
        <v-form ref="loginForm"
                class="multi-col-validation"
                lazy-validation
                v-model="valid">
            <div>
                <v-row class="d-flex">
                    <v-col dir="ltr"
                           cols="12"
                           lg="7"
                           md="7"
                           sm="12">
                        <p class="font-weight-semibold arabic-otp mb-4 mt-3 text-color-black"
                           style="font-size: 13px;">

                            {{ $t(`Enter Card's last 4 digits`) }}
                        </p>
                        <v-otp-input v-model="lastFourDigit"
                                     :rules="fourDigit"
                                     :hide-details="true"
                                     @input="onOtpInput"
                                     ref="otpInput"
                                     @blur="onInputBlur"
                                     onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                     type="number"
                                     class="mb-2"
                                     @finish="onFinish"
                                     length="4"></v-otp-input>
                        <div v-if="otpError"
                             style="font-size: 12px;"
                             class="ar-forgototp error-message">
                            {{ $t(otpErrorMessage) }}
                        </div>
                    </v-col><v-col cols="12"
                           lg="5"
                           md="5"
                           sm="12">
                        <p class="ar-datelabel en-datelabel font-weight-semibold pt-1 mb-5 mt-2 text-color-black"
                           style="font-size: 13px;">
                            {{ $t(`Enter Card's expiry date`) }}
                        </p>

                        <v-text-field v-model="maskedExpiryDate"
                                      outlined
                                      :placeholder="$t('MM-YY')"
                                      v-mask="timeMask"
                                      :rules="expiry"
                                      hide-details="auto"
                                      class="mb-2 ar-expiryField">
                            <template #message="{ message }">
                                {{ $t(message) }}
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12"
                           lg="12"
                           md="12"
                           sm="12">
                        <p class="font-weight-semibold text-color-black mb-4"
                           style="font-size: 13px;">

                            {{ $t('Please enter your Personal ID/Iqama number') }}
                        </p>
                        <v-text-field v-model="legalId"
                                      outlined
                                      type="text"
                                      :placeholder="$t('Enter Personal ID/Iqama number')"
                                      :rules="nationalRules"
                                      hide-details="auto"
                                      class="mb-2">
                            <template #message="{ message }">
                                {{ $t(message) }}
                            </template>
                        </v-text-field>

                    </v-col>
                    <v-col cols="12"
                           lg="12">
                        <p class="font-weight-semibold text-color-black mb-2"
                           style="font-size: 14px;">
                            <!-- {{ $t('Enter the last four digits of your card number.') }} -->
                            {{ $t('Security Check') }}
                        </p>
                    </v-col>
                    <v-col cols="12"
                           lg="12"
                           md="12"
                           sm="12"
                           class="security-dark">
                        <v-col cols="12"
                               lg="12"
                               md="12"
                               sm="12">
                            <p class="font-weight-semibold mb-5 mt-0 text-blue"
                               style="font-size: 13px;">

                                {{ $t('Please enter the characters in image') }}
                            </p>
                            <div style="display:flex">

                                <div style="width: 161px;height: 40px;">
                                    <v-img contain
                                           max-width="100%"
                                           class="auth-3d-group overflow-hidden"
                                           :src="captchaImage">
                                    </v-img>

                                </div>
                                <span class="ar-referesh-btn referesh-btn">
                                    <v-btn icon
                                           color="primary"
                                           type="button"
                                           @click="requestCaptcha"
                                           large
                                           class="py-3 mt-1">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </span>

                            </div>
                        </v-col>
                        <v-col lg="12">
                            <div>
                                <div>
                                    <v-text-field v-model="captchaValue"
                                                  outlined
                                                  type="text"
                                                  :placeholder="$t('Enter the characters')"
                                                  v-mask="'######'"
                                                  :rules="captchaRules"
                                                  hide-details="auto"
                                                  class="mb-2 captcha-input">
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </v-text-field>
                                </div>
                            </div>
                        </v-col>
                    </v-col>
                    <v-col lg="12"
                           md="12"
                           sm="12">
                        <v-btn block
                               color="primary"
                               type="button"
                               @click="submit"
                               :disabled="legalId.length < 10 || captchaValue.length < 6 || lastFourDigit.length < 4 || maskedExpiryDate.length < 5"
                               x-large
                               class="btn-dark">

                            {{ $t('Next') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { required } from '@core/utils/validation';
import { ref } from '@vue/composition-api'
import alert from './alert.vue';
import store from '@/store'
import TermsCondition from '@/views/TermsCondition.vue';
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import sharedServices from '@/services/shared-services'
import enrollmentServices from '@/services/enrollment-services'

import restErrorMessages from '@/@core/utils/rest-error-messages';

export function timeMask(value)
{
    const month = [
        /[0-1]/, value.charAt(0) === '0' ? /[0-9]/ : /[0-2]/,
    ];
    const year = [/[0-9]/, /[0-9]/];
    return value.length > 2 ? [...month, '-', ...year] : month;
}

export default {
    components: {
        AppBarI18n,
        AppBarThemeSwitcher,
        alert,
        TermsCondition
    },

    data: () => ({
        valid: false,
        timeMask,
        lastFourDigit: '',
        modall: false,
        expiryDate: '',
        maskedExpiryDate: '',
        disableSubmit: true,
        otpError: false,
        otpErrorMessage: '',
        enableNext: false,
        legalId: '', // From User Input
        captchaValue: '', // From UserInput
        captchaId: '', // From API
        captchaImage: '', // From API to Display Captcha Image
        genericRules: [
            v => !!v || 'Field is required'
        ],
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        captchaRules: [
            (value) => !!value || ('Captcha is required.'),
            (value) => (value && value.length >= 6) || ('Captcha must be 6 digits'),
        ],
        expiry: [
            (value) => !!value || ('Expiry date is required.'),
            (value) => (value && value.length >= 5) || ('Expiry date is required.'),
        ],
        fourDigit: [
            (value) => !!value || ('Last 4 digits are required.')
        ],
        nationalRules: [
            (value) => !!value || ('Personal ID is required.'),
            // (value) => (value && value.length >= 10) || ('Personal ID must be 10 digits'),
        ],
    }),
    mounted()
    {
        window.scrollTo(0, 0);
        store.dispatch('loader/show');
        sharedServices.getToken('enr', "").then(tokenRes =>
        {
            enrollmentServices.getTokenForEnrollment(tokenRes.data.results).then(enrollmentTokenRes =>
            {
                store.set('requestKeys/enrollmentToken', enrollmentTokenRes.data.accessToken);
                this.requestCaptcha();
            })
        })

    },

    methods: {
        onOtpInput()
        {

            if (this.lastFourDigit.length !== 4)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Last 4 digits are required.';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false;
            }
        },
        onInputBlur()
        {
            if (!this.lastFourDigit.length)
            {
                this.otpError = true;
                this.otpErrorMessage = 'Last 4 digits are required.';
            }
        },
        openPopup()
        {
            if (this.modall == false)
            {
                this.modall = true
            } else
            {
                this.modall = false
            }
        },

        closeDialog()
        {
            this.modall = false;
        },
        onFinish(rsp)
        {
        },
        back()
        {
            this.$emit('back');
        },
        submit()
        {
            // store.set('enrollment/lastFourDigit', this.lastFourDigit)
            // store.set('enrollment/expiryDate', this.expiryDate)
            // this.$emit('submit')
            this.verifyAndGetOTP()
        },
        requestCaptcha()
        {
            sharedServices.getCaptcha().then(captchaResponse =>
            {
                this.captchaImage = 'data:image/jpg;base64,' + captchaResponse.data.captcha;
                this.captchaId = captchaResponse.data.captchaId;
            })
        },
        verifyAndGetOTP()
        {
            let dict = {
                captchaId: this.captchaId,
                captchaValue: this.captchaValue,
            }

            enrollmentServices.verifyAndGetOTP(dict).then(otpResponse =>
            {
                if (otpResponse?.data?.responseInfo?.errorCode == "00000")
                {
                    this.enableNext = true;
                    this.$emit('submit')
                }
                else
                {
                    if (otpResponse?.data?.responseInfo?.errorCode != "00000")
                    {
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode ? otpResponse?.data?.responseInfo?.errorCode : otpResponse?.error?.response?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }

                    else if (otpResponse?.error?.response?.status != 200)
                    {
                        this.alertMessage = restErrorMessages.get(otpResponse?.data?.responseInfo?.errorCode, otpResponse?.data?.responseInfo?.errorDescription ? otpResponse?.data?.responseInfo?.errorDescription : otpResponse?.error?.response?.data?.responseInfo?.errorDescription);
                        this.alertType = 'error'
                        this.showAlert = true
                        setTimeout(() =>
                        {
                            this.showAlert = false
                        }, 5000)
                    }
                }

            })
        },
    },
    watch: {
        lastFourDigit: function (newVal, oldVal)
        {
            if (newVal == 'e')
            {
                alert('knskfdkfd')
            }
            if (newVal.length == 4)
            {
                store.set('enrollment/lastFourDigit', this.lastFourDigit)
                if (this.maskedExpiryDate.length == 5 && this.legalId != '')
                {
                    this.disableSubmit = false;
                }
                else
                {
                    this.disableSubmit = true;
                }
            }
            else
            {
                this.disableSubmit = true;
            }
        },
        legalId: function (newVal, oldVal)
        {
            store.set('enrollment/legalId', newVal);
        },
        maskedExpiryDate: function (newVal, oldVal)
        {
            if (newVal.length == 5)
            {
                let finalExpiry = newVal.split('-').map(s => s.trim());
                finalExpiry[1] = '-20' + finalExpiry[1]
                this.expiryDate = finalExpiry[0] + finalExpiry[1]
                store.set('enrollment/expiryDate', this.expiryDate)

                if (this.lastFourDigit.length == 4)
                {
                    this.disableSubmit = false;
                }
                else
                {
                    this.disableSubmit = true;
                }
            }
            else
            {
                this.disableSubmit = true;
            }
        }
    },
    setup()
    {
        // Template Ref
        const loginForm = ref(null)

        const email = ref('')
        const errorMessages = ref([])

        return {
            errorMessages,
            validators: {
                required
            },

            // Template Refs
            loginForm,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.referesh-btn {
    background: #D9D9D9;
    padding: 5px;
    margin-left: 8px;
    margin-top: -1px;
    border-radius: 6px;
}

.captcha-input {
    width: 60%;
}

.error-message {
    color: red;
    margin: -6px 10px;
}

.divide {
    height: 235px;
    position: relative;
    left: 74px;
    right: 74px;
    top: 48px;
}

.en-datelabel {
    font-size: 13px;
}

@media screen and (max-width: 950px) {

    .divide {
        display: none;
    }

}

@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }

    .text-blue {
        font-size: 14px !important;
    }

}

@media screen and (max-width: 600px) {

    .captcha-input {
        width: 100%;
    }

}

.text-2xl {
    font-size: 1.3rem !important;
    line-height: 2rem !important;
}
</style>
